import { gsap } from '../../node_modules/gsap/index.js';

const DEFAULT_HEIGHT = 115;

window.customElements.define("lauralonni-frame", class extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <div class="empty"></div>
      <div class="background"></div>
      <div class="border top"></div>
      <div class="border right"></div>
      <div class="border bottom"></div>
      <div class="border left"></div>
    `;

    this._empty = this.querySelector(".empty");
    this._background = this.querySelector(".background");
    this._borderTop = this.querySelector(".border.top");
    this._borderBottom = this.querySelector(".border.bottom");
    this._borderLeft = this.querySelector(".border.left");
    this._borderRight = this.querySelector(".border.right");

    this._duration = 0;
    this._previousHeight = 0;

    window.addEventListener("frame", (e) => {
      const detail = Object.assign({
        zIndex: "",
        position: "",
        backgroundZIndex: "",
        frameZIndex: "",
        duration: .8,
        backgroundColor: "grey"
      }, e.detail);

      this._duration = detail.duration;

      this.style.position = detail.position;
      this.style.zIndex = detail.zIndex;

      this._background.style.zIndex = detail.backgroundZIndex;
      this._borderTop.style.zIndex = this._borderBottom.style.zIndex = this._borderLeft.style.zIndex = this._borderRight.style.zIndex = detail.frameZIndex;

      this._empty.style.height = detail.height || `${DEFAULT_HEIGHT}px`;
      this.update();

      this._background.style.transitionDuration = `${this._duration}s`;
      this._background.style.backgroundColor = detail.backgroundColor;
    });

    window.addEventListener("resize", () => {
      this.update();
    });

    this.update();
  }

  update() {
    let height = this._empty.getBoundingClientRect().height;

    gsap.killTweensOf(this._background);
    gsap.killTweensOf(this._borderBottom);
    gsap.killTweensOf(this._borderLeft);
    gsap.killTweensOf(this._borderRight);

    gsap.to(this._background, this._duration, {
      height: height,
      delay: height < this._previousHeight ? .1 : 0,
      ease: "back.inOut(1)"
    });

    gsap.to(this._borderBottom, this._duration, {
      top: height - 25,
      delay: height < this._previousHeight ? 0 : .1,
      ease: "back.inOut(1)"
    });

    gsap.to(this._borderLeft, this._duration, {
      height: height - 50,
      delay: height < this._previousHeight ? 0 : .1,
      ease: "back.inOut(1)"
    });

    gsap.to(this._borderRight, this._duration, {
      height: height - 50,
      delay: height < this._previousHeight ? 0 : .1,
      ease: "back.inOut(1)"
    });

    this._previousHeight = height;
  }
});
