import Loader from 'dlib/utils/Loader.js'

const template = document.createElement('template')
Loader.load('src/menu/template.html').then((value) => {
  template.innerHTML = value
})

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-menu', class extends HTMLElement {
    connectedCallback() {
      const templateClone = document.importNode(template.content, true)
      this.appendChild(templateClone)

      this._color = ''
      this.tabIndex = 0

      const hamburgerButton = this.querySelector('.hamburger-button')
      const hamburgerButtonAnimation = hamburgerButton.querySelector('dlib-lottie')
      hamburgerButton.addEventListener('mouseenter', () => {
        if (!hamburgerButtonAnimation.paused) {
          return
        }
        hamburgerButtonAnimation.currentTime = 0
        hamburgerButtonAnimation.play()
      })

      window.addEventListener('click', (e) => {
        this.classList.toggle('open', hamburgerButton.contains(e.target))
      })

      window.addEventListener('hashchange', () => {
        for (const element of [...this.querySelectorAll('li')]) {
          element.classList.toggle('highlight', element.dataset.match ? window.location.hash.startsWith(`#${element.dataset.match}`) : window.location.hash === element.dataset.match)
        }
      })

      window.addEventListener('frame', (e) => {
        if (e.detail.backgroundColor) {
          this._color = e.detail.backgroundColor === 'transparent' ? '#b0b3c5' : e.detail.backgroundColor
          if (window.innerWidth <= 600) {
            this.style.color = this._color
          }
        }
      })

      window.addEventListener('resize', () => {
        this.style.color = window.innerWidth <= 600 ? this._color : ''
      })
    }
  })
})
