import '../maintext/index.js'
import '../secondarytext/index.js'
import '../media/index.js'
import '../contact/index.js'
import '../menu/index.js'
import '../logo/index.js'
import '../frame/index.js'
import '../reelpage/index.js'
import '../homepage/index.js'
import '../workspage/index.js'
import '../workpage/index.js'
import '../aboutpage/index.js'
import '../contactpage/index.js'

import Loader from 'dlib/utils/Loader.js'

const template = document.createElement('template')
Loader.load('src/main/template.html').then((value) => {
  template.innerHTML = value
})

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-main', class extends HTMLElement {
    connectedCallback() {
      document.querySelector('.lauralonni-loader').classList.add('hide')

      setTimeout(() => {
        const templateClone = document.importNode(template.content, true)
        this.appendChild(templateClone)

        const hashRegExp = /^#(.*?)(\/|$)/

        window.addEventListener('hashchange', () => {
          const matches = hashRegExp.exec(window.location.hash)
          this.page = matches ? matches[1] : ''
        })

        requestAnimationFrame(() => {
          window.dispatchEvent(new Event('hashchange'))
        })

        if (!hashRegExp.test(window.location.hash)) {
          window.location.hash = ''
        }

        window.addEventListener('footercontact', (e) => {
          this.querySelector('lauralonni-main > lauralonni-contact').visible = e.detail.visible
        })
      }, 500)
    }

    get page() {
      return this._page
    }

    set page(value) {
      if (this._page === value) {
        return
      }
      this._page = value

      const tagName = `lauralonni-page-${this._page || 'home'}`

      const currentPage = this.querySelector('.page')
      const pageElement = currentPage && currentPage.tagName === tagName.toUpperCase() ? currentPage : document.createElement(tagName)
      pageElement.classList.add('page')

      if (pageElement === currentPage) {
        pageElement.visible = true
      } else if (currentPage.visibilityPromise) {
        currentPage.visible = false
        const page = this.page
        currentPage.visibilityPromise.then(() => {
          if (this.page !== page) {
            return
          }
          this.replaceChild(pageElement, currentPage)
        })
      } else {
        this.replaceChild(pageElement, currentPage)
      }
    }
  })
})
