import Loader from "dlib/utils/Loader.js";
import ViewElement from "dlib/customelements/ViewElement.js";
import Keyboard from "dlib/input/Keyboard.js";
import Ticker from "dlib/utils/Ticker.js";
import { gsap } from 'gsap/index.js';

let WORKS;
const WORKS_MAP = new Map();
Loader.load("cms/data.json").then((value) => {
  WORKS = value.works;
  for (let work of WORKS) {
    WORKS_MAP.set(work.slug, work);
  }
});

let template = document.createElement("template");
Loader.load("src/workspage/template.html").then((value) => {
  template.innerHTML = value;
});

Loader.onLoad.then(() => {
  window.customElements.define("lauralonni-page-works", class extends ViewElement {
    connectedCallback() {
      this.classList.add("hide");
      
      let templateClone = document.importNode(template.content, true);
      this.appendChild(templateClone);

      if(this.style.maskImage === undefined && this.style.webkitMaskImage === undefined) {
        this.querySelector(".works").classList.add("no-mask-image");
      }

      let workListInnerHTML = "";
      this._workList = this.querySelector(".works ul");
      for (let work of WORKS) {
        workListInnerHTML += `<li id="${work.slug}"><lauralonni-maintext visible="false" href="#works/${work.slug}">${work.title}</lauralonni-maintext></li>`;
      }
      this._workList.innerHTML = workListInnerHTML;

      this._media = this.querySelector("lauralonni-media");

      this._eye = {
        x: 0,
        y: 0,
        offsetX: 0,
        offsetY: 0,
        targetOffsetX: 0,
        targetOffsetY: 0,
        initialCenterX: 0,
        initialCenterY: 0,
        closed: 0,
        element: this.querySelector("aside .more svg"),
        pupil: this.querySelector("aside .more svg circle"),
        eyelid: this.querySelector("aside .more svg > path"),
        mask: this.querySelector("aside .more svg mask path")
      };
      this._eye.element.style.opacity = "0";
      this._eye.initialCenterX = this._eye.pupil.cx.baseVal.value;
      this._eye.initialCenterY = this._eye.pupil.cy.baseVal.value;

      Ticker.add(this._updateEyeBinded = this._updateEye.bind(this));
      window.addEventListener("mousemove", this._moveEyeBinded = this._moveEye.bind(this));
      window.addEventListener("hashchange", this._checkHashBinded = this._checkHash.bind(this));
      window.addEventListener("resize", this._resizeBinded = this._resize.bind(this));
      window.addEventListener("keydown", this._onKeyDownBinded = this._onKeyDown.bind(this));

      
      this.dispatchEvent(new CustomEvent("footercontact", {
        detail: {
          visible: true
        },
        bubbles: true
      }));
      
      this.querySelector(".works ul").addEventListener("scroll", this._onWorksScrollBinded = this._onWorksScroll.bind(this));
      
      this._checkHash();
      this._setupAnimations();

      super.connectedCallback();      

      this._resize();
    }
    
    disconnectedCallback() {
      gsap.killTweensOf(this._eye);
      Ticker.delete(this._updateEyeBinded);
      window.removeEventListener("mousemove", this._moveEyeBinded);
      window.removeEventListener("hashchange", this._checkHashBinded);
      window.removeEventListener("resize", this._resizeBinded);
      window.removeEventListener("keydown", this._onKeyDownBinded);
      window.removeEventListener("scroll", this._onWorksScrollBinded);
      super.disconnectedCallback();
    }

    _setupAnimations() {
      const closeEye = (delay) => {
        gsap.to(this._eye, .1, {
          closed: 1,
          delay,
          yoyo: true,
          repeat: 1,
          onComplete: () => {
            closeEye(delay > 3 && Math.random() < .5 ? .05 : 3 + Math.random() * 3);
          }
        });
      }
      closeEye(3);
    }

    visibilityExecutor(resolve, view) {
      gsap.fromTo(this._eye, .2, {
        closed: view.visible ? 1 : this._eye.closed
      }, {
        closed: view.visible ? 0 : 1,
        delay: view.visible ? 2 : 0
      });

      gsap.fromTo(this._eye.element, .2, {
        opacity: view.visible ? 0 : 1
      }, {
        opacity: view.visible ? 1 : 0,
        delay: view.visible ? 2 : 0
      });

      setTimeout(() => {
        this.classList.toggle("hide", !view.visible);

        if(view.visible) {
          setTimeout(() => {
            for (let text of [...this.querySelectorAll("lauralonni-maintext")]) {
              text.visible = true;
            }

            for (let text of [...this.querySelectorAll("lauralonni-secondarytext")]) {
              text.visible = true;
            }

            setTimeout(() => {
              this._media.visible = true;
              resolve();    
            }, 200);
          }, 400);
        }
        else {
          setTimeout(() => {
            resolve();    
          }, 800);
        }
      }, view.visible ? 800 : 0);
    }

    _moveEye(e) {
      this._eye.targetOffsetX = Math.max(-50, Math.min(50, (e.clientX - this._eye.x) * .2));
      this._eye.targetOffsetY = Math.max(-30, Math.min(30, (e.clientY - this._eye.y) * .2));
    }
    
    _updateEye() {
      this._eye.offsetX += (this._eye.targetOffsetX - this._eye.offsetX) * .2;
      this._eye.offsetY += (this._eye.targetOffsetY - this._eye.offsetY) * .2;
      this._eye.pupil.style.cx = `${this._eye.initialCenterX + this._eye.offsetX}`;
      this._eye.pupil.style.cy = `${this._eye.initialCenterY + this._eye.offsetY}`;
      this._eye.mask.setAttribute("transform", `translate(0, ${111 * this._eye.closed}) scale(1, ${1.01 - this._eye.closed})`);
      this._eye.eyelid.setAttribute("transform", `translate(0, ${111 * this._eye.closed}) scale(1, ${1.01 - this._eye.closed})`);
    }

    _onWorksScroll(e) {
      e.target.parentNode.classList.toggle("top", e.target.scrollTop === 0);
      e.target.parentNode.classList.toggle("bottom", e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 1);
    }

    _checkHash() {
      let matches = /^#works\/*(.*?)(\/|$)/.exec(window.location.hash);
      if(matches) {
        this.work = matches[1] || window.innerWidth <= 600 ? matches[1] : WORKS[0].slug;
      }
    }

    _resize() {
      if(this._work && window.innerWidth <= 600) {
        window.location.hash = `work/${this._work}`;
      } else if(!this._work && window.innerWidth > 600) {
        window.location.hash = `works/${WORKS[0].slug}`;
      }
      const eyeBoundingClientRect = this._eye.eyelid.getBoundingClientRect();
      this._eye.x = eyeBoundingClientRect.x + eyeBoundingClientRect.width * .5;
      this._eye.y = eyeBoundingClientRect.y + eyeBoundingClientRect.height * .5;
    }

    _onKeyDown (e) {
      let works = [...WORKS_MAP.keys()];
      let currentWorkId = works.indexOf(this.work);
      if(e.keyCode === Keyboard.DOWN) {
        window.location.hash = `works/${works[(currentWorkId + 1) % works.length]}`;
      } else if(e.keyCode === Keyboard.UP) {
        window.location.hash = `works/${works[currentWorkId - 1 < 0 ? works.length - 1 : currentWorkId - 1]}`;
      }
    }

    get work() {
      return this._work;
    }

    set work(value) {
      if(this._work === value) {
        return;
      }
      this._work = value;

      if(this._work && window.innerWidth <= 600) {
        window.location.hash = `work/${this._work}`;
      } else {
        window.location.hash = `works/${this._work}`;
      }

      let workData = WORKS_MAP.get(this._work) || {};
      let color = workData.color || "#b0b3c5";

      for (let li of [...this.querySelectorAll(".works li")]) {
        li.classList.toggle("highlight", li.id === this._work);
      }

      this.style.color = color;

      if(this._work) {
        this.querySelector("h3 lauralonni-maintext").innerHTML = workData.title;

        let isIframe = /:\/\//.test(workData.media);
        this._media.color = color;
        this._media.src = isIframe ? `${workData.media}?title=0&byline=0&portrait=0&color=${workData.color.substring(1)}` : workData.media;

        const type = this.querySelector(".type");
        const typeVisible = type.visible;
        type.visible = false;
        const promise = type.visibilityPromise;
        type.visibilityPromise.then(() => {
          if(promise !== type.visibilityPromise) {
            return;
          }
          type.innerHTML = workData.type;
          type.visible = typeVisible;
        });

        const client = this.querySelector(".client");
        const clientVisible = client.visible;
        if(workData.client) {
          client.visible = false;
          const promise = client.visibilityPromise;
          client.visibilityPromise.then(() => {
            if(promise !== client.visibilityPromise) {
              return;
            }
            client.innerHTML = workData.client;
            client.visible = clientVisible;
          });
          client.style.display = "";
        } else {
          client.style.display = "none";
        }

        this.querySelector(".more a").href = `#work/${this._work}`;
      }

      this.dispatchEvent(new CustomEvent("frame", {
        detail: {
          height: "calc(100% - 70px)",
          backgroundColor: color
        },
        bubbles: true
      }));

      this.dispatchEvent(new CustomEvent("logo", {
        detail: {
          color: workData.color ? color : "white" 
        },
        bubbles: true
      }));

      this._resize();
    }
  });
});
