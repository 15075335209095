import 'dlib/customelements/SpriteAnimationElement.js'

import ViewElement from 'dlib/customelements/ViewElement.js'

import Loader from 'dlib/utils/Loader.js'

import { gsap } from 'gsap/index.js'

import '../icon/index.js'

const template = document.createElement('template')
Loader.promises.set({}, new Promise((resolve) => {
  Loader.load('src/contact/template.html').then((value) => {
    template.innerHTML = value
    const spriteAnimations = []
    for (const video of [...template.content.querySelectorAll('dlib-spriteanimation')]) {
      spriteAnimations.push(video.getAttribute('src'))
    }
    Loader.load(spriteAnimations).then((animationsData) => {
      const images = []
      for (const [i, animationData] of animationsData.entries()) {
        images.push(`${/(.*[\/\\]).*$/.exec(spriteAnimations[i])[1]}${animationData.meta.image}`)
      }
      Loader.load(images).then(() => {
        resolve()
      })
    })
  })
}))

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-contact', class extends ViewElement {
    connectedCallback() {
      const templateClone = document.importNode(template.content, true)
      this.appendChild(templateClone)

      this._animations = [...this.querySelectorAll('dlib-spriteanimation')]

      super.connectedCallback()

      for (const animation of this._animations) {
        animation.addEventListener('mouseenter', () => {
          const duration = .4
          animation.pause()
          gsap.to(animation, duration * .2, {
            currentTime: .65,
            ease: 'none',
          })
          gsap.to(animation, duration * .8, {
            currentTime: 1,
            delay: duration * .2,
            ease: 'none',
          })
        })
      }
    }

    visibilityExecutor(resolve, view) {
      for (const [i, animation] of this._animations.entries()) {
        setTimeout(() => {
          animation.playbackRate = view.visible ? 1.5 : -3
          animation.play()
          setTimeout(() => {
            resolve()
          }, 300)
        }, (view.visible ? 300 + 75 * i : 25 * i))
      }
    }
  })
})
