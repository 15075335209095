import '@damienmortini/damdom-lottie'

import Loader from 'dlib/utils/Loader.js'
import ViewElement from 'dlib/customelements/ViewElement.js'
import Keyboard from 'dlib/input/Keyboard.js'

import { gsap } from 'gsap/index.js'

const template = document.createElement('template')
Loader.load('src/homepage/template.html').then((value) => {
  template.innerHTML = value
})

let DATA
const WORKS_MAP = new Map()
Loader.load('cms/data.json').then((value) => {
  DATA = value
  for (const work of DATA.works) {
    WORKS_MAP.set(work.slug, work)
  }
})

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-page-home', class extends ViewElement {
    #currentWorkId = 0
    #buttons
    #frameHeight
    #work
    #featuredVideo
    #frameColor

    connectedCallback() {
      this.classList.add('hide')

      const templateClone = document.importNode(template.content, true)
      this.appendChild(templateClone)

      this.#featuredVideo = this.querySelector('.work video')
      this.#featuredVideo.addEventListener('ended', this.#nextWork)

      // Arrows
      this.querySelector('.work .arrow.right').addEventListener('click', this.#nextWork)
      this.querySelector('.work .arrow.left').addEventListener('click', this.#previousWork)

      // Radio Buttons
      const onButtonClick = (e) => {
        this.#currentWorkId = parseInt(e.currentTarget.id)
        this.work = DATA.featured[this.#currentWorkId]
      }

      const buttonsContainer = this.querySelector('.work .buttons')
      this.#buttons = []
      for (const index of DATA.featured.keys()) {
        const button = document.createElement('input')
        button.type = 'radio'
        button.name = 'work'
        button.checked = !index
        button.id = index
        button.addEventListener('click', onButtonClick)
        buttonsContainer.appendChild(button)
        this.#buttons.push(button)
      }

      // Buttons

      for (const button of [...this.querySelectorAll('nav ul li')]) {
        const lottieElement = button.querySelector('damdom-lottie')
        lottieElement.src = `src/homepage/${button.id}.json`
        lottieElement.loop = true
        let currentSegment = lottieElement.segments = [0, 50]
        lottieElement.addEventListener('ended', () => {
          lottieElement.segments = currentSegment
        })
        button.addEventListener('mouseenter', () => {
          currentSegment = [50, 100]
        })
        button.addEventListener('mouseleave', () => {
          currentSegment = [0, 50]
        })
      }

      this.work = DATA.featured[this.#currentWorkId]

      window.addEventListener('keydown', this.#onKeyDown)
      window.addEventListener('resize', this.#resize)

      this.dispatchEvent(new CustomEvent('logo', {
        detail: {
          color: 'white',
        },
        bubbles: true,
      }))

      this.dispatchEvent(new CustomEvent('footercontact', {
        detail: {
          visible: true,
        },
        bubbles: true,
      }))

      this.#resize()

      super.connectedCallback()
    }

    disconnectedCallback() {
      window.removeEventListener('keydown', this.#onKeyDown)
      window.removeEventListener('resize', this.#resize)
    }

    visibilityExecutor(resolve, view) {
      this.classList.toggle('hide', !view.visible)

      const timeline = gsap.timeline({ onComplete: resolve })

      for (const [i, element] of [...this.querySelectorAll('.buttons input')].entries()) {
        timeline.fromTo(element, .4, {
          scale: view.visible ? 1.5 : 1,
          opacity: view.visible ? 0 : 1,
        },
          {
            opacity: view.visible ? 1 : 0,
            scale: view.visible ? 1 : 1.5,
            delay: i * .1,
            ease: view.visible ? 'back.out' : null,
          }, 0)
      }

      for (const [i, element] of [...this.querySelectorAll('nav li')].entries()) {
        timeline.fromTo(element, .5, {
          scale: view.visible ? 2 : 1,
          opacity: view.visible ? 0 : 1,
        },
          {
            opacity: view.visible ? 1 : 0,
            scale: view.visible ? 1 : 1.5,
            delay: (view.visible ? .6 : 0) + i * .2,
            ease: view.visible ? 'back.out' : null,
          }, 0)
      }

      for (const [i, element] of [...this.querySelectorAll('.arrow')].entries()) {
        timeline.fromTo(element, .5, {
          scale: view.visible ? .5 : 1,
          opacity: view.visible ? 0 : 1,
        },
          {
            opacity: view.visible ? 1 : 0,
            scale: view.visible ? 1 : .5,
            delay: i * .2,
            ease: view.visible ? 'back.out' : null,
          }, 0)
      }

      if (!view.visible) {
        this.work = null
      }
    }

    #onKeyDown = (e) => {
      if (e.keyCode === Keyboard.RIGHT) {
        this.#nextWork()
      } else if (e.keyCode === Keyboard.LEFT) {
        this.#previousWork()
      }
    }

    #nextWork = () => {
      this.#currentWorkId = (this.#currentWorkId + 1) % DATA.featured.length
      this.work = DATA.featured[this.#currentWorkId]
    }

    #previousWork = () => {
      this.#currentWorkId = this.#currentWorkId - 1 < 0 ? DATA.featured.length - 1 : this.#currentWorkId - 1
      this.work = DATA.featured[this.#currentWorkId]
    }

    #resize = () => {
      this.#frameHeight = this.querySelector('.work').offsetHeight + 90
      this.dispatchEvent(new CustomEvent('frame', {
        detail: {
          height: `${this.#frameHeight}px`,
          backgroundColor: this.#frameColor,
        },
        bubbles: true,
      }))
    }

    set work(value) {
      if (this.#work === value) {
        return
      }

      this.#work = value

      const workData = WORKS_MAP.get(this.#work)

      if (!workData) {
        return
      }

      this.#featuredVideo.src = `cms/${workData.slug}/featured.${this.#featuredVideo.canPlayType('video/mp4;codecs="hvc1"') ? 'mov' : 'webm'}`

      this.#buttons[DATA.featured.indexOf(this.#work)].checked = true
      for (const a of [...this.querySelectorAll('.work a')]) {
        a.href = `#work/${workData.slug}`
      }

      const a = this.querySelector('.work h2 lauralonni-secondarytext')

      a.visible = false
      const promise = a.visibilityPromise
      a.visibilityPromise.then(() => {
        if (promise !== a.visibilityPromise) {
          return
        }
        a.textContent = workData.title
        a.visible = true
      })

      this.querySelector('nav').style.color = workData.color
      for (const path of [...this.querySelectorAll('nav lauralonni-icon svg path')]) {
        path.style.stroke = workData.color
      }

      this.#frameColor = workData.color

      this.dispatchEvent(new CustomEvent('frame', {
        detail: {
          duration: 1.4,
          height: `${this.#frameHeight}px`,
          backgroundColor: this.#frameColor,
        },
        bubbles: true,
      }))
    }
  })
})
