import Loader from 'dlib/utils/Loader.js'
import ViewElement from 'dlib/customelements/ViewElement.js'

const template = document.createElement('template')
Loader.load('src/contactpage/template.html').then((value) => {
  template.innerHTML = value
})

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-page-contact', class extends ViewElement {
    connectedCallback() {
      super.connectedCallback()

      const templateClone = document.importNode(template.content, true)
      this.appendChild(templateClone)

      this.dispatchEvent(new CustomEvent('frame', {
        detail: {
          height: '115px',
          backgroundColor: '#b2dbd3',
        },
        bubbles: true,
      }))

      this.dispatchEvent(new CustomEvent('logo', {
        detail: {
          color: 'white',
        },
        bubbles: true,
      }))

      this.dispatchEvent(new CustomEvent('footercontact', {
        detail: {
          visible: false,
        },
        bubbles: true,
      }))
    }

    visibilityExecutor(resolve, view) {
      setTimeout(() => {
        this.querySelector('lauralonni-contact').visible = view.visible
        setTimeout(resolve, 400)
      }, view.visible ? 200 : 0)
    }
  })
})
