import ViewElement from 'dlib/customelements/ViewElement.js'

import Loader from 'dlib/utils/Loader.js'

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-icon', class extends ViewElement {
    connectedCallback() {
      super.connectedCallback()

      this.svg = this.dataset.svg
    }

    visibilityExecutor(resolve, view) {
      let callback
      this.addEventListener('transitionend', callback = () => {
        this.removeEventListener('transitionend', callback)
        resolve()
      })
      if (view.visible) {
        this.style.opacity = '0'
      }
      this.style.transitionTimingFunction = view.visible ? 'ease-out' : 'ease-in'
      this.offsetHeight
      this.style.opacity = view.visible ? '1' : '0'
    }

    set svg(value) {
      if (!value) {
        return
      }
      Loader.load(value).then((data) => {
        this.innerHTML = data
      })
    }
  })
})
