import Loader from 'dlib/utils/Loader.js'
import ViewElement from 'dlib/customelements/ViewElement.js'
import { gsap } from 'gsap/index.js'

const template = document.createElement('template')
Loader.load('src/aboutpage/template.html').then((value) => {
  template.innerHTML = value
})

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-page-about', class extends ViewElement {
    connectedCallback() {
      const templateClone = document.importNode(template.content, true)
      this.appendChild(templateClone)

      this.dispatchEvent(new CustomEvent('logo', {
        detail: {
          color: 'white',
        },
        bubbles: true,
      }))

      this.dispatchEvent(new CustomEvent('footercontact', {
        detail: {
          visible: true,
        },
        bubbles: true,
      }))

      this._resize()

      window.addEventListener('resize', this._resizeBinded = this._resize.bind(this))

      window.scrollTo(0, 0)

      super.connectedCallback()
    }

    visibilityExecutor(resolve, view) {
      const colors = [
        '#a0baea',
        '#b2dbd3',
        '#e2bac3',
        '#a6a6f4',
      ]

      const articles = this.querySelectorAll('article')

      for (const [i, article] of [...articles].entries()) {
        if (view.visible) {
          const drop = document.createElement('div')
          drop.classList.add('drop')
          drop.style.top = `${50 + (Math.random() * 2 - 1) * 10}%`
          drop.style.left = `${50 + (Math.random() * 2 - 1) * 10}%`
          drop.style.background = colors[i]
          article.appendChild(drop)
          drop.offsetHeight

          const fallDuration = .4
          const spreadDuration = .8
          const delay = .2 + i * .1

          gsap.fromTo(drop, fallDuration, {
            scale: .2,
            opacity: 0,
            rotation: Math.random() * 180,
          }, {
            scale: .01,
            opacity: 1,
            delay,
            ease: 'power1.in',
          })

          gsap.to(drop, spreadDuration, {
            scale: 2,
            delay: fallDuration + delay,
            ease: 'power3.out',
          })

          gsap.fromTo(article.querySelector('h4'), spreadDuration * .7, {
            opacity: 0,
            y: 20,
          }, {
            opacity: 1,
            y: 0,
            delay: fallDuration + delay,
          })

          gsap.fromTo(article.querySelector('p'), spreadDuration * .7, {
            opacity: 0,
            y: 20,
          }, {
            opacity: 1,
            y: 0,
            delay: fallDuration + delay + .2,
            onComplete: () => {
              if (i === articles.length - 1) {
                resolve()
              }
            },
          })
        } else {
          const duration = .3

          gsap.to(article.querySelector('h4'), duration, {
            opacity: 0,
            scale: .9,
            ease: 'power2.in',
          })

          gsap.to(article.querySelector('p'), duration, {
            opacity: 0,
            scale: .9,
            delay: .2,
            ease: 'power2.in',
          })

          gsap.to(article.querySelector('.drop'), duration, {
            opacity: 0,
            delay: .4,
            ease: 'power2.inOut',
            onComplete: () => {
              if (i === articles.length - 1) {
                resolve()
              }
            },
          })
        }
      }
    }

    disconnectedCallback() {
      window.removeEventListener('resize', this._resizeBinded)
    }

    _resize() {
      this.dispatchEvent(new CustomEvent('frame', {
        detail: {
          height: window.innerWidth < 700 ? '115px' : 'calc(100% - 70px)',
          backgroundZIndex: window.innerWidth < 700 ? 2 : 0,
          frameZIndex: 2,
          backgroundColor: window.innerWidth < 700 ? '#f9acad' : '#eeeeee',
        },
        bubbles: true,
      }))
    }
  })
})
