import ViewElement from 'dlib/customelements/ViewElement.js'

import Loader from 'dlib/utils/Loader.js'

import { gsap } from 'gsap/index.js';

(() => {
  const style = document.createElement('style')
  style.textContent = `
    lauralonni-maintext {
      position: relative;
      will-change: mask-image, -webkit-mask-image;
      mask-image: linear-gradient(90deg, white -100%, transparent 0%);
      -webkit-mask-image: linear-gradient(90deg, white -100%, transparent 0%);
    }
  `
  document.head.appendChild(style)
})()

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-maintext', class extends ViewElement {
    connectedCallback() {
      super.connectedCallback()

      this._a = document.createElement('a')

      this.href = this.getAttribute('href')

      for (const child of [...this.childNodes]) {
        this._a.appendChild(child)
      }
      this.appendChild(this._a)
    }

    visibilityExecutor(resolve, view) {
      this.style.pointerEvents = view.visible ? '' : 'none'

      const duration = 1 * (view.visible ? 1 : .3)

      const progress = { value: 0 }
      gsap.to(progress, duration, {
        value: 1,
        onUpdate: () => {
          this.style.maskImage = this.style.webkitMaskImage = `linear-gradient(90deg, ${view.visible ? 'white' : 'transparent'} ${progress.value * 200 - 100}%, ${view.visible ? 'transparent' : 'white'} ${progress.value * 200}%)`
        },
        onComplete: resolve,
      })
    }

    set textContent(value) {
      this._a.textContent = value
    }

    set innerHTML(value) {
      this._a.innerHTML = value
    }

    set href(value) {
      if (value) {
        this._a.href = value
      }
    }
  })
})
