import ViewElement from "../../node_modules/dlib/customelements/ViewElement.js";

import Loader from "../../node_modules/dlib/utils/Loader.js";

import { gsap } from '../../node_modules/gsap/index.js';

(() => {
  let style = document.createElement("style");
  style.textContent = `
    lauralonni-secondarytext {
      position: relative;
    }

    lauralonni-secondarytext span span {
      will-change: transform;
    }
    
    lauralonni-secondarytext a::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  `;
  document.head.appendChild(style);
})();

Loader.onLoad.then(() => {
  window.customElements.define("lauralonni-secondarytext", class extends ViewElement {
    connectedCallback() {
      this._a = document.createElement("a");
      this._onMouseEnterBinded = this.onMouseEnter.bind(this);

      this.href = this.getAttribute("href");

      for (let child of [...this.childNodes]) {
        this._a.appendChild(child);
      }
      this.appendChild(this._a);

      this._compute();

      super.connectedCallback();
    }

    visibilityExecutor(resolve, view) {
      this.style.pointerEvents = view.visible ? "" : "none";

      const upDuration = .2;
      const downDuration = view.visible ? .3 : .2;
      const staggerDelay = Math.min((.8 / this._spans.length) * (view.visible ? 1 : .3), .05);

      if (this._timeline) {
        this._timeline.kill();
      }

      this._timeline = gsap.timeline({
        onComplete: resolve
      });

      for (let [i, span] of this._spans.entries()) {
        this._timeline.fromTo(span, upDuration, {
          y: 0,
          scaleX: 1,
          scaleY: view.visible ? 0 : 1
        }, {
          y: -5,
          scaleX: .8,
          scaleY: 1.2,
          transformOrigin: "50% 75%",
          delay: staggerDelay * i,
          ease: "quint.out"
        }, 0);

        this._timeline.to(span, downDuration, {
          y: 0,
          scaleX: 1,
          scaleY: view.visible ? 1 : 0,
          delay: upDuration + staggerDelay * i,
          ease: view.visible ? "back.out" : "quint.out"
        }, 0);
      }
    }

    _compute() {
      if (this._timeline) {
        this._timeline.kill();
      }

      this._spans = [];

      const traverse = (parent) => {
        for (let child of [...parent.childNodes]) {
          if (child.nodeType !== Node.TEXT_NODE) {
            traverse(child);
            continue;
          }

          if (child.textContent.trim().length === 0) {
            continue;
          }

          let parentSpan;

          for (let [i, letter] of [...child.textContent].entries()) {
            if (letter === " " || i === 0) {
              parentSpan = document.createElement("span");
              parentSpan.style.display = "inline-block";
              parentSpan.style.whiteSpace = "nowrap";
              parent.appendChild(parentSpan);
            }
            const span = document.createElement("span");
            span.innerHTML = letter === " " ? "&nbsp;" : letter;
            span.style.display = "inline-block";
            this._spans.push(span);
            parentSpan.appendChild(span);

          }
          parent.removeChild(child);
        }
      }

      traverse(this._a);

      if (!this.visible) {
        for (let span of this._spans) {
          span.style.transform = "scaleY(0)";
        }
        this.style.pointerEvents = "none";
      }
    }

    set textContent(value) {
      this._a.textContent = value;
      this._compute();
    }

    set innerHTML(value) {
      this._a.innerHTML = value;
      this._compute();
    }

    set href(value) {
      if (value) {
        this._a.href = value;
        this._a.addEventListener("mouseenter", this._onMouseEnterBinded);
      } else {
        this._a.removeEventListener("mouseenter", this._onMouseEnterBinded);
      }
    }

    disconnectedCallback() {
      this._a.removeEventListener("mouseenter", this._onMouseEnterBinded);
      super.disconnectedCallback();
    }

    onMouseEnter() {
      const flippedLetterId = Math.floor(Math.random() * this._spans.length);

      const upDuration = .2;
      const downDuration = .2;
      const staggerDelay = .04;

      for (let [i, span] of this._spans.entries()) {
        // const flip = i === flippedLetterId;
        const flip = Math.random() < .1;
        const durationScale = flip ? 2.5 : 1;

        gsap.to(span, upDuration * durationScale, {
          y: flip ? -15 : -4,
          scaleX: .8,
          scaleY: 1.2,
          delay: staggerDelay * i,
          transformOrigin: "50% 50%",
          ease: "quint.out"
        });

        gsap.to(span, downDuration * durationScale, {
          y: 0,
          scaleX: 1,
          scaleY: 1,
          delay: upDuration * durationScale + staggerDelay * i,
          ease: "back.inOut"
        });

        gsap.fromTo(span, (upDuration + downDuration) * durationScale, {
          rotation: flip ? -360 : 0
        }, {
          rotation: 0,
          delay: staggerDelay * i,
          ease: "quint.inOut"
        });
      }
    }
  });
});
