import ViewElement from 'dlib/customelements/ViewElement.js'

import CSSTimingFunction from 'dlib/css/CSSTimingFunction.js';

(() => {
  const style = document.createElement('style')
  style.textContent = `
    lauralonni-media {
      position: relative;
      line-height: 0;
    }

    lauralonni-media .media-mask {
      ${CSSTimingFunction.all()}
      will-change: transform;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: grey;
      transform: scaleY(0);
      z-index: 1;
    }

    lauralonni-media img,
    lauralonni-media iframe {
      background: black;
      width: 100%;
    }
    
    lauralonni-media iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  `
  document.head.appendChild(style)
})()

window.customElements.define('lauralonni-media', class extends ViewElement {
  constructor() {
    super()

    this._mask = document.createElement('div')
    this._mask.classList.add('media-mask')
    this._mask.style.transform = 'scaleY(0)'
  }

  connectedCallback() {
    this.appendChild(this._mask)

    super.connectedCallback()
  }

  visibilityExecutor(resolve, view) {
    this._changeAnimation().then(resolve)
  }

  _changeAnimation() {
    return new Promise((resolve) => {
      const onElementLoad = () => {
        this._element.removeEventListener('load', onElementLoad)
        if (this._previousElement) {
          this.replaceChild(this._element, this._previousElement)
        }
        this._element.style.visibility = this.visible ? 'visible' : 'hidden'
        this._previousElement = this._element

        const duration = this.visible ? .4 : .2

        this._mask.style.transitionTimingFunction = 'var(--ease-in-out-cubic), ease'
        this._mask.style.transitionDuration = `${duration}s`
        this._mask.style.transformOrigin = '50% 100%'
        this._mask.style.transform = 'scaleY(0)'

        setTimeout(() => {
          resolve()
        }, duration * 1000)
      }

      const onMaskTransitionEnd = () => {
        if (!this._element) {
          resolve()
          return
        }

        if (!this.visible) {
          onElementLoad()
          return
        }

        this._element.addEventListener('load', onElementLoad)
        this._element.src = this._src
      }

      const duration = this.visible ? .4 : .2

      this._mask.style.transitionProperty = 'transform, color'
      this._mask.style.transitionDuration = `${duration}s`
      this._mask.style.transformOrigin = '50% 0%'
      this._mask.style.transitionTimingFunction = 'var(--ease-in-out-cubic), ease'
      this._mask.style.transform = 'scaleY(1)'

      setTimeout(() => {
        onMaskTransitionEnd()
      }, duration * 1000)
    })
  }

  set color(value) {
    this._mask.style.backgroundColor = value
  }

  set src(value) {
    this._src = value
    const isImage = /\.(png|jpg|gif)$/.test(this._src)
    this._element = document.createElement(isImage ? 'img' : 'iframe')
    if (!isImage) {
      this._element.setAttribute('allowfullscreen', '')
      this._element.setAttribute('frameborder', '0')
    }
    this._element.style.visibility = 'hidden'
    this.appendChild(this._element)
    if (this.visible) {
      this._changeAnimation()
    }
  }
})
