import Loader from 'dlib/utils/Loader.js'
import { styleToRGBA } from '@damienmortini/math'
import '@damienmortini/damdom-glslcanvas/index.js'
import { gsap } from 'gsap/index.js'
import {GLTexture} from '@damienmortini/webgl'

const template = document.createElement('template')
let imageTexture
Loader.load([
  'src/logo/template.html',
  'src/logo/logo.png',
]).then(([templateContent, image]) => {
  template.innerHTML = templateContent
  imageTexture = image
})

Loader.onLoad.then(() => {
  window.customElements.define('lauralonni-logo', class extends HTMLElement {
    connectedCallback() {
      const templateClone = document.importNode(template.content, true)
      this.appendChild(templateClone)

      const glslCanvas = document.querySelector('damdom-glslcanvas')
      glslCanvas.fragment = `#version 300 es
      precision highp float;
      
      uniform vec2 resolution;
      uniform sampler2D imageTexture;
      uniform vec3 color;
      uniform float progress;

      out vec4 fragColor;
      
      void main() {
        vec2 uv = gl_FragCoord.xy / resolution;
        uv.y = 1. - uv.y;
        uv.y /= 46.;
        uv.y += progress * (1. - (1. / 46.));
        fragColor = vec4(gl_FragCoord.xy / resolution, 0., 1.);
        fragColor = texture(imageTexture, uv);
        fragColor.rgb *= color;
      }`
      glslCanvas.uniforms.set('imageTexture', new GLTexture({
        gl: glslCanvas.gl,
        data: imageTexture,
      }))

      window.addEventListener('logo', (e) => {
        const rgba = styleToRGBA(e.detail.color)
        glslCanvas.uniforms.set('color', [rgba[0], rgba[1], rgba[2]])
        glslCanvas.draw()
        this.style.color = e.detail.color || 'white'
      })

      const progress = { value: 0 }
      this.addEventListener('mouseenter', () => {
        if (window.innerWidth <= 600) {
          return
        }
        this.classList.add('hover')
        gsap.killTweensOf(progress)
        progress.value = 0
        gsap.to(progress, 1.5, {
          value: 1,
          ease: 'steps(45)',
          onUpdate: () => {
            glslCanvas.uniforms.set('progress', progress.value)
            glslCanvas.draw()
          },
          onComplete: () => {
            this.classList.remove('hover')
          },
        })
      })
    }
  })
})
