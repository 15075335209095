import Loader from "dlib/utils/Loader.js";
import ViewElement from "dlib/customelements/ViewElement.js";
import Keyboard from "dlib/input/Keyboard.js";

let WORKS;
const WORKS_MAP = new Map();
Loader.load("cms/data.json").then((value) => {
  WORKS = value.works;
  for (let work of WORKS) {
    WORKS_MAP.set(work.slug, work);
  }
});

Loader.onLoad.then(() => {
  window.customElements.define("lauralonni-page-work", class extends ViewElement {
    connectedCallback() {
      super.connectedCallback();

      this._checkHashBinded = this._checkHash.bind(this);

      window.addEventListener("hashchange", this._checkHashBinded);
      window.addEventListener("keydown", this._onKeyDownBinded = this._onKeyDown.bind(this));

      this.dispatchEvent(new CustomEvent("logo", {
        detail: {
          color: "white" 
        },
        bubbles: true
      }));

      this.dispatchEvent(new CustomEvent("footercontact", {
        detail: {
          visible: true
        },
        bubbles: true
      }));

      this._checkHash();
    }
    
    disconnectedCallback() {
      window.removeEventListener("keydown", this._onKeyDownBinded);
      window.removeEventListener("hashchange", this._checkHashBinded);
    }

    _checkHash() {
      let matches = /^#work\/(.*?)(\/|$)/.exec(window.location.hash);
      this.work = matches ? matches[1] : "";
    }

    _onKeyDown (e) {
      let works = [...WORKS_MAP.keys()];
      let currentWorkId = works.indexOf(this.work);
      if(e.keyCode === Keyboard.RIGHT) {
        window.location.hash = `#work/${works[(currentWorkId + 1) % works.length]}`;
      } else if(e.keyCode === Keyboard.LEFT) {
        window.location.hash = `#work/${works[currentWorkId - 1 < 0 ? works.length - 1 : currentWorkId - 1]}`;
      }
    }

    get work() {
      return this._work;
    }

    set work(value) {
      if(!value || this._work === value) {
        return;
      }
      this._work = value;

      let workData = WORKS_MAP.get(this._work) || {};
      let color = workData.color || "#b0b3c5";

      this.style.color = color;

      this.dispatchEvent(new CustomEvent("frame", {
        detail: {
          height: "115px",
          zIndex: 2,
          position: "fixed",
          backgroundColor: color
        },
        bubbles: true
      }));

      Loader.load(`cms/${this._work}/index.html`).then((data) => {
        const workId = WORKS.indexOf(workData);
        const previousWork = WORKS[workId === 0 ? WORKS.length - 1 : workId - 1];
        const nextWork = WORKS[(workId + 1) % WORKS.length];

        this.innerHTML = data + `
          <footer>
            <a href="#work/${previousWork.slug}" class="previous" style="color: ${previousWork.color}">
              <svg width="19.17" height="32.87" viewBox="0 0 19.17 32.87"><path fill="${previousWork.color}" d="M18.84,3.49,5.9,16.44,18.84,29.38a1,1,0,0,1,0,1.52L17.2,32.54a1,1,0,0,1-1.52,0L.33,17.19a1,1,0,0,1,0-1.52L15.68.33a1,1,0,0,1,1.52,0L18.84,2a1.08,1.08,0,0,1,.33.76A1.14,1.14,0,0,1,18.84,3.49Z"/></svg>
              <span class="full">${previousWork.title}</span>
            </a>
            <lauralonni-secondarytext href="#works/${window.innerWidth <= 600 ? "" : this._work}" class="back">Work</lauralonni-secondarytext>
            <a href="#work/${nextWork.slug}" class="next" style="color: ${nextWork.color}">
              <span class="full">${nextWork.title}</span>
              <svg width="19.17" height="32.87" viewBox="0 0 19.17 32.87"><path fill="${nextWork.color}" d="M.33,29.38,13.27,16.44.33,3.49A1.14,1.14,0,0,1,0,2.73,1.14,1.14,0,0,1,.33,2L2,.33A1.14,1.14,0,0,1,2.73,0a1.13,1.13,0,0,1,.76.33L18.84,15.68a1,1,0,0,1,0,1.52L3.49,32.54a1.13,1.13,0,0,1-.76.33A1.13,1.13,0,0,1,2,32.54L.33,30.9A1.08,1.08,0,0,1,0,30.14,1.14,1.14,0,0,1,.33,29.38Z"/></svg>
            </a>
          </footer>
        `;

        for (let rawMedia of [...this.querySelectorAll("section iframe, section img")]) {
          const media = document.createElement("lauralonni-media");
          media.className = rawMedia.className;
          if(rawMedia instanceof HTMLIFrameElement) {
            media.classList.add("iframe");
            const div = document.createElement("div");
            div.style.paddingTop = `${rawMedia.height / rawMedia.width * 100}%`;
            media.appendChild(div);
          }
          rawMedia.className = "";
          media.src = rawMedia.src;
          media.color = color;
          rawMedia.parentNode.replaceChild(media, rawMedia);
        }

        for (let script of [...this.querySelectorAll("script")]) {
          const newScript = document.createElement("script");
          newScript.src = script.src;
          newScript.async = true;
          script.parentNode.replaceChild(newScript, script);
        }
      });
      
      window.scrollTo(0, 0);
    }
  });
});
