import ViewElement from "dlib/customelements/ViewElement.js";
import Loader from "dlib/utils/Loader.js";

let template = document.createElement("template");
Loader.load("src/reelpage/template.html").then((value) => {
  template.innerHTML = value;
});

let REEL_DATA;
Loader.load("cms/data.json").then((value) => {
  REEL_DATA = value.showreel;
});

Loader.onLoad.then(() => {
  window.customElements.define("lauralonni-page-reel", class extends ViewElement {
    connectedCallback() {
      super.connectedCallback();

      let templateClone = document.importNode(template.content, true);
      this.appendChild(templateClone);

      this._resizeBinded = this._resize.bind(this);

      this._media = this.querySelector("lauralonni-media");

      this.dispatchEvent(new CustomEvent("frame", {
        detail: {
          height: "115px",
          backgroundColor: REEL_DATA.color,
        },
        bubbles: true
      }));

      this.dispatchEvent(new CustomEvent("logo", {
        detail: {
          color: "white"
        },
        bubbles: true
      }));

      this.dispatchEvent(new CustomEvent("footercontact", {
        detail: {
          visible: true
        },
        bubbles: true
      }));

      this._resize();

      window.addEventListener("resize", this._resizeBinded);

      this._media.src = `${REEL_DATA.video}?title=0&byline=0&portrait=0&color=ffffff`;
      this._media.color = REEL_DATA.color;

      // Fix for previous links e.g. #reel/2019
      window.location.hash = `reel`;

      setTimeout(() => {
        this._media.visible = true;
      }, 1000);
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      window.removeEventListener("resize", this._resizeBinded);
    }

    _resize() {
      this._media.style.width = "";
      this._media.style.height = "";
      const ratio = 16 / 9;
      if (this._media.offsetWidth / this._media.offsetHeight > ratio) {
        this._media.style.width = `${Math.ceil(this._media.offsetHeight * ratio)}px`;
      } else {
        this._media.style.height = `${Math.ceil(this._media.offsetWidth * (1 / ratio))}px`;
      }
    }
  });
});
